<template>
  <common :title="title">
    <div class="face" @click="takePhoto">
      <img src="./img/sanma.png" alt="" />
    </div>
  </common>
</template>

<script>
import storage from "../utils/storage";
import {
  uploadUrl,
  aoiRegister,
  aoiLogin,
  applyRealnameAuditUrl,
} from "./api.js";
import { base64toFile } from "@/utils/utils.js";
import common from "../components/common.vue";
export default {
  name: "humanFaceRegister",
  components: {
    common,
  },
  props: {},
  data() {
    return {
      isRegister: "",
      title: "",
      faceInfo: {
        localPath: "",
        facePic: "",
      },
    };
  },
  created() {
    const { isRegister } = this.$route.query;
    if (![null, undefined, ""].includes(isRegister)) {
      this.isRegister = isRegister;
      this.title = isRegister == 0 ? "人脸注册登录" : "人脸登录";
    }
  },
  mounted() {
    //获取人脸图片信息
    window.onPhotoTake = this.onPhotoTake;
    //监听人脸成功状态
    window.onFacePassRecognized = this.onFacePassRecognized;
  },
  methods: {
    //拍照获取结果
    async onPhotoTake(localPath, imageBase64) {
      await this.uploadImg(base64toFile(imageBase64));
      this.faceInfo.localPath = localPath;
      storage.set("faceInfo", this.faceInfo);
      this.$router.push({
        name: "faceSubmission",
      });

      // window.sys.nativeSet("facePic", this.faceInfo.facePic);
      // window.sys.nativeSet("localPath", this.faceInfo.localPath);
    },
    //人脸识别结果
    onFacePassRecognized(userId) {
      this.aoiLogin(userId);
    },
    //人脸登录
    aoiLogin(userId) {
      this.$axios.post(aoiLogin, { userId, registerType: 2, deviceNo: storage.get('deviceNo') || '' }).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          storage.set("systemUserInfo", data);
          this.$store.commit("setUserId", data.userId);
          this.$store.commit("setHouseId", data.houseId);
          this.$store.commit("setTenantId", data.tenantId);
          this.$store.commit("setCommunityId", data.communityId);
          this.$router.push({
            name: "guidePage",
          });
        }
      });
    },

    //拍照
    takePhoto() {
      //注册
      if (this.isRegister == 0) {
        window.sys.takePhoto();
      } else {
        window.sys.loginByFacePass();
      }
      // 选择图片
    },
    //
    // 转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(uploadUrl, formData);
      if (res.code == 200) {
        this.faceInfo.facePic = res.data.link;
        console.log("this.faceInfo.facePic----->" + this.faceInfo.facePic);
      }
    },
  },
};
</script>

<style scoped lang="less">
.face {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 514px;
    height: 304px;
  }
}
</style>
