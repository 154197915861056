// 上传
const uploadUrl = `/gateway/blade-resource/oss/endpoint/put-file`;
//获取区级联动接口
const areaLinkUrl = "/gateway/hc-space/space/getSpaceTreeList";
//一体机注册
const aoiRegister = "/gateway/hc-mini/user/aio/aoiRegister";
//一体机登录
const aoiLogin = "/gateway/hc-mini/user/aio/aoiLogin";
export {
  uploadUrl,
  areaLinkUrl,
  aoiRegister,
  aoiLogin,
};
